// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../node_modules/css-loader/dist/cjs.js!./small.css";
import ___CSS_LOADER_AT_RULE_IMPORT_1___ from "-!../../node_modules/css-loader/dist/cjs.js!./medium.css";
import ___CSS_LOADER_AT_RULE_IMPORT_2___ from "-!../../node_modules/css-loader/dist/cjs.js!./large.css";
import ___CSS_LOADER_AT_RULE_IMPORT_3___ from "-!../../node_modules/css-loader/dist/cjs.js!./utils.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_1___, "screen and (min-width: 576px)");
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_2___, "screen and (min-width: 768px)");
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Outfit:wght@400;700&display=swap);"]);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "a {\n  color: inherit;\n  text-decoration: none;\n}\n\nimg {\n  width: 100%;\n  display: block;\n}\n\n* {\n  margin: 0;\n  padding: 0;\n  box-sizing: border-box;\n}\n\nbody {\n  background: #0e0e0e;\n  color: #ccc;\n  font-family: 'Outfit', sans-serif;\n  overflow-x: hidden;\n}\n", "",{"version":3,"sources":["webpack://./src/styles/main.css"],"names":[],"mappings":"AAMA;EACE,cAAc;EACd,qBAAqB;AACvB;;AAEA;EACE,WAAW;EACX,cAAc;AAChB;;AAEA;EACE,SAAS;EACT,UAAU;EACV,sBAAsB;AACxB;;AAEA;EACE,mBAAmB;EACnB,WAAW;EACX,iCAAiC;EACjC,kBAAkB;AACpB","sourcesContent":["@import url(./small.css);\n@import url(./medium.css) screen and (min-width: 576px);\n@import url(./large.css) screen and (min-width: 768px);\n@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@400;700&display=swap');\n@import url(./utils.css);\n\na {\n  color: inherit;\n  text-decoration: none;\n}\n\nimg {\n  width: 100%;\n  display: block;\n}\n\n* {\n  margin: 0;\n  padding: 0;\n  box-sizing: border-box;\n}\n\nbody {\n  background: #0e0e0e;\n  color: #ccc;\n  font-family: 'Outfit', sans-serif;\n  overflow-x: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
